import FuelcardFormField from '../FuelcardFormField';
import FuelcardFormData from '../../../../model/FuelcardFormData';
import { SetValueConfig, UseFormReturn } from 'react-hook-form';
import { ChangeEvent } from 'react';
import UserLogin from '../../../../model/user/UserLogin';

interface NewCronosOrExternalUserCommonFieldsProps {
    fuelcard: FuelcardFormData;
    isAdminOrManager: boolean;
    handleChange: <K extends keyof FuelcardFormData>(
        name: K,
        value: FuelcardFormData[K],
        setValue: (name: K, value: FuelcardFormData[K], options?: Partial<SetValueConfig>) => void
    ) => void;
    methods: UseFormReturn<FuelcardFormData, any, undefined>;
    userInfo: UserLogin;
}

export default function NewCronosOrExternalUserCommonFields(props: NewCronosOrExternalUserCommonFieldsProps) {
    props.fuelcard.mobile = props.fuelcard.mobile.replaceAll(' ', '');
    return (
        <>
            <div hidden={!props.isAdminOrManager && /^\+?\d{9,13}$/.test(props.userInfo.phoneNumber)}>
                <FuelcardFormField
                    label="Mobile"
                    type="text"
                    name="mobile"
                    autoComplete="tel"
                    value={props.fuelcard.mobile}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange('mobile', e.target.value, props.methods.setValue)}
                    register={props.methods.register}
                    registerOptions={{
                        required: 'Mobile is required.',
                        pattern: {
                            value: /^\+?\d{9,13}$/,
                            message: 'Valid formats: "0468330850" or "+32468330850" or "0032468330850"'
                        }
                    }}
                />
            </div>
            <div hidden={!props.isAdminOrManager}>
                <FuelcardFormField
                    label="First Name"
                    type="text"
                    name="firstName"
                    autoComplete="firstName"
                    value={props.fuelcard.firstName}
                    readOnly={!props.isAdminOrManager}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange('firstName', e.target.value, props.methods.setValue)}
                    register={props.methods.register}
                    registerOptions={{
                        required: 'First name is required.',
                        maxLength: 50
                    }}
                />

                <FuelcardFormField
                    label="Last Name"
                    type="text"
                    name="lastName"
                    autoComplete="lastName"
                    value={props.fuelcard.lastName}
                    readOnly={!props.isAdminOrManager}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => props.handleChange('lastName', e.target.value, props.methods.setValue)}
                    register={props.methods.register}
                    registerOptions={{
                        required: 'Last name is required.',
                        maxLength: 50
                    }}
                />
            </div>
        </>
    );
}
