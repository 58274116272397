import { Dropdown } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import ChargingStation from '../../../model/ChargingStation';
import { mapStatus } from '../../../service/StatusMapper';
import CommonDropdownButton from '../../shared/CommonDropdownButton';
import UserFuelcard from '../../../model/fuelcard/UserFuelcard';

interface DashboardFilterButtonProps {
    chargingStations: ChargingStation[];
    userFuelcards: UserFuelcard[];
    isAdminOrManager: boolean;
    filter: string | null;
    setFilter: (filter: string | null) => void;
    isUserCharging: boolean;
}

export default function DashboardFilterButton(props: DashboardFilterButtonProps) {
    const [possibleStatuses, setPossibleStatuses] = useState<string[]>([]);

    useEffect(() => {
        const mappedStatuses = Array.from(
            new Set(
                props.chargingStations
                    .map((cs) => (cs.comStatus === 'DOWN' && !props.isAdminOrManager ? cs.comStatus : cs.ocppStatus))
                    .map((csStatus) => mapStatus(csStatus))
            )
        );

        let currentStatus = localStorage.getItem('dashboardFilter');
        if (currentStatus && currentStatus.startsWith('status-')) {
            currentStatus = currentStatus?.replace(/^status-/, '');
        }
        if (currentStatus !== null && currentStatus !== 'all' && mappedStatuses.length > 0 && !mappedStatuses.includes(currentStatus)) {
            props.setFilter('all');
        }
        setPossibleStatuses(mappedStatuses);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.chargingStations, props.isAdminOrManager]);

    const filteringToText =
        props.filter == null || props.filter === '' || props.filter === 'all'
            ? 'Filter dashboard'
            : 'Filtering to ' + (props.filter === 'session' ? "'your charging session'" : "'" + props.filter.replace('status-', '') + "'");

    const statusesToShow = possibleStatuses.filter((csStatus) => 'status-' + csStatus !== props.filter);
    if (props.chargingStations.some((cs) => cs.isBlocked)) {
        statusesToShow.push('Blocked');
    }

    return (
        <CommonDropdownButton title={'Filter dashboard'} dropDownChild={filteringToText}>
            {props.filter == null || props.filter === '' ? (
                <></>
            ) : (
                <>
                    <Dropdown.Header>{filteringToText}</Dropdown.Header>
                    <Dropdown.Divider />
                </>
            )}

            {props.filter === null || props.filter === 'all' || props.filter === '' ? (
                <></>
            ) : (
                <>
                    <Dropdown.Item onClick={() => props.setFilter('all')}>Show full dashboard</Dropdown.Item>
                    <Dropdown.Divider />
                </>
            )}
            {props.chargingStations.some((cs) => cs.contactEmail !== null && cs.contactEmail !== undefined) && props.filter !== 'pool' ? (
                <>
                    <Dropdown.Item onClick={() => props.setFilter('pool')}>My pool</Dropdown.Item>
                    <Dropdown.Divider />
                </>
            ) : (
                <></>
            )}
            {props.isUserCharging && props.filter !== 'session' ? (
                <>
                    <Dropdown.Item onClick={() => props.setFilter('session')}>Show my session</Dropdown.Item>
                    <Dropdown.Divider />
                </>
            ) : (
                <></>
            )}

            {statusesToShow.length === 0 ? (
                <Dropdown.Header>No status filter available</Dropdown.Header>
            ) : (
                <>
                    <Dropdown.Header>Filter to status:</Dropdown.Header>
                    {statusesToShow.sort().map((cs) => (
                        <Dropdown.Item key={cs} onClick={() => props.setFilter('status-' + cs)}>
                            {cs}
                        </Dropdown.Item>
                    ))}
                </>
            )}
        </CommonDropdownButton>
    );
}
