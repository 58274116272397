import InformationPopover from '../../../shared/InformationPopover';
import SpeedMeter from '../../../shared/icon/SpeedMeter';
import useCheckIfAdminOrManager from '../../../../hooks/useCheckIfAdminOrManager';
import Car from '../../../../model/car/Car';

interface KwhProps {
    car: Car | null | undefined;
    kWh: string;
    slowCharging: boolean | null | undefined;
}

export default function Kwh(props: KwhProps) {
    const isAdmin = useCheckIfAdminOrManager();
    const getFormattedKWh = (toFormat: string): string => {
        if (toFormat === '0') {
            return '0.00';
        } else if (toFormat.includes('.')) {
            const parts = toFormat.split('.');
            return `${parts[0]}.${parts[1].padEnd(2, '0')}`;
        } else {
            return toFormat;
        }
    };

    return (
        <div className="kWh">
            {isAdmin && props.slowCharging && <SpeedMeter car={props.car} />}
            <InformationPopover
                icon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
                        {/* Bootstrap Icon "arrow-up" */}
                        <path
                            fillRule="evenodd"
                            d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"
                        />
                    </svg>
                }
                desktopPopoverStyle={{ width: 'fit-content', maxWidth: '95vw' }}
                mobilePopoverStyle={{ width: 'fit-content', maxWidth: '95vw' }}
                desktopOverLayPlacement={'bottom'}
                overlayDelay={{ show: 350, hide: 0 }}
            >
                <p>The amount this station has charged so far</p>
            </InformationPopover>
            <p className="NoPaddingOrMargin biggerFontSize">{getFormattedKWh(props.kWh)} kWh</p>
        </div>
    );
}
