import { ChangeEvent, ClipboardEvent, useEffect, useState } from 'react';
import { FormProvider, SetValueConfig, useForm } from 'react-hook-form';
import axios from 'axios';
import Eula from '../../../model/Eula';
import Fuelcard from '../../../model/fuelcard/Fuelcard';
import FuelcardFormData from '../../../model/FuelcardFormData';
import FuelcardRegistrationProblemDetail from '../../../model/problemDetail/FuelcardRegistrationProblemDetail';
import FuelcardFormField from './FuelcardFormField';
import { acceptEula, checkFuelcard, getLatestEula, getUserOwnCar, postRegisterFuelcard } from '../../../service/Api';
import '../../../styling/fuelcardForm.css';
import Button from 'react-bootstrap/Button';
import useGetUserInfo from '../../../hooks/useGetUserInfo';
import NavigationBar from '../../shared/NavigationBar';
import Footer from '../../shared/Footer';
import useCheckIfAdminOrManager from '../../../hooks/useCheckIfAdminOrManager';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import LoginRedirect from '../../login/LoginRedirect';
import SessionExpiredErrorMessage from '../../shared/SessionExpiredErrorMessage';
import { useQuery } from 'react-query';
import { Alert, Spinner } from 'react-bootstrap';
import InformationModal from './modals/InformationModal';
import ScanFuelCardButton from './ScanFuelCardButton';
import ManualFallbackModal from './modals/ManualFallbackModal';
import InformationPopover from '../../shared/InformationPopover';
import ToastMessage from '../../shared/ToastMessage';
import { Variant } from 'react-bootstrap/types';
import FcAlreadyExistsModal from './modals/FcAlreadyExistsModal';
import OtherUserCarModal from './modals/OtherUserCarModal';
import FeedbackOffCanvas from '../../feedback/FeedbackOffCanvas';
import { FeedBackType } from '../../../model/feedback/FeedBackType';
import { UserType } from '../../../model/enum/UserType';
import moment from 'moment-timezone';
import UserSearch from './usersearch/UserSearch';
import { MngrUserRegistrationState } from '../../../model/MngrUserRegistrationState';
import CarRegistration from './car/CarRegistration';
import useLikelySupportsNfc from '../../../hooks/useLikelySupportsNfc';

export default function FuelCardForm() {
    const location = useLocation();
    const navigate = useNavigate();
    const methods = useForm<FuelcardFormData>();
    const userInfo = useGetUserInfo();
    const isAdminOrManager = useCheckIfAdminOrManager();
    const likelySupportsWebNfc = useLikelySupportsNfc();

    const [message, setMessage] = useState<string | JSX.Element | null>(null);
    const [messageType, setMessageType] = useState<'success' | 'danger' | 'info' | null>(null);
    const [messageTypeNfc, setMessageTypeNfc] = useState<'success' | 'error' | 'info' | null>(null);
    const [updates, setUpdates] = useState<string>('');
    const [fuelcard, setFuelCard] = useState<FuelcardFormData>({
        serialNumber: location.state?.serialNumber ?? '',
        displayName: isAdminOrManager ? 'Fuelcard ' + moment().format('DD/MM/YYYY') : '',
        firstName: isAdminOrManager ? '' : userInfo.firstName,
        lastName: isAdminOrManager ? '' : userInfo.surname,
        contactEmail: isAdminOrManager ? '' : userInfo.email,
        primaryEmail: isAdminOrManager ? '' : userInfo.primaryEmail,
        mobile: isAdminOrManager ? '' : userInfo.phoneNumber.replaceAll(' ', ''),
        lastModificationDate: null,
        licensePlate: '',
        brand: '',
        model: '',
        isBlocked: false,
        userId: 0,
        terms: false
    });
    const [eula, setEula] = useState<Eula | null>(null);
    const [showInformationModal, setShowInformationModal] = useState<boolean>(false);
    const [showManualFallbackModal, setShowManualFallbackModal] = useState<'info' | 'warning' | null>(null);
    const [isFcIdReadOnly, setIsFcIdReadOnly] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [showAlreadyExistsModal, setShowAlreadyExistsModal] = useState<boolean>(false);
    const [toast, setToast] = useState<{ message: string; bg?: Variant; autoHide: boolean; title?: string }>({
        message: '',
        autoHide: true
    });
    const [showOtherUserCarModal, setShowOtherUserCarModal] = useState<boolean>(false);
    const [feedback, setFeedBack] = useState<{
        show: boolean;
        additionalFeedBackTypes: FeedBackType[];
        selectedFeedbackType: FeedBackType;
        defaultMessage: string;
    }>({
        show: false,
        additionalFeedBackTypes: [FeedBackType.CAR_REASSIGN_REQUEST],
        selectedFeedbackType: FeedBackType.CAR_REASSIGN_REQUEST,
        defaultMessage: ''
    });
    const [mngrUserRegistrationState, setMngrUserRegistrationState] = useState<MngrUserRegistrationState>('initial');
    const [mngrUserRegistrationSearchQuery, setMngrUserRegistrationSearchQuery] = useState<string>('');

    //Any FC that is exactly 12 digits or that starts with BE IDA
    const likelyIncorrectFcIdRegex = new RegExp(
        '(^\\d{12}$)|(^[bBdD][eE][.\\-_ ]?[dD][iI][aA])|([bB][eE][.\\-_ ]?[lL][mM][sS][.\\-_ ]?\\d{6}[.\\-_ ]?\\d)'
    );

    const [usePrefilledCar, setUsePrefilledCar] = useState<boolean | null>(null);
    const [hideCarRegistration, setHideCarRegistration] = useState<boolean>(false);

    const { data: userCar, isSuccess: isUserCarLoaded } = useQuery('carOfUser', () => getUserOwnCar(), {
        refetchOnWindowFocus: false,
        enabled: !isAdminOrManager,
        onError: () => setUsePrefilledCar(false)
    });

    useEffect(() => {
        if (!isAdminOrManager && isUserCarLoaded) {
            const currentFuelcardCarDataIsBlank = fuelcard.brand === '' && fuelcard.model === '' && fuelcard.licensePlate === '';
            const userCarDataIsPresent = userCar?.data?.brand && userCar?.data?.model && userCar?.data?.licensePlate;
            if (currentFuelcardCarDataIsBlank && userCarDataIsPresent) {
                methods.setValue('brand', userCar.data.brand);
                methods.setValue('model', userCar.data.model);
                methods.setValue('licensePlate', userCar.data.licensePlate);
                setFuelCard({
                    ...fuelcard,
                    brand: userCar.data.brand,
                    model: userCar.data.model,
                    licensePlate: userCar.data.licensePlate
                });
                setUsePrefilledCar(true);
            }
            if (!userCarDataIsPresent) {
                setUsePrefilledCar(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserCarLoaded, userCar]);

    useEffect(() => {
        if (!isAdminOrManager && eula == null) {
            getLatestEula()
                .then((resp) => setEula(resp.data))
                .catch(() => {
                    setMessage('Something went wrong while trying to fetch the latest eula.  Please try again later.');
                    setMessageType('danger');
                });
        }
    }, [isAdminOrManager, eula]);

    useEffect(() => {
        if (fuelcard.model !== '' && (usePrefilledCar === false || (isAdminOrManager && mngrUserRegistrationState !== 'alreadyRegisteredWithCar'))) {
            handleChange('model', '', methods.setValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fuelcard.brand]);

    const setMessageWithType = (message: string | JSX.Element, type: 'success' | 'danger' | 'info' | null) => {
        setMessage(message);
        setMessageType(type);
    };

    const handleResponseStatus = (
        status: number | undefined,
        defaultErrorMessage: string,
        errorMessage?: string
    ): {
        message: string | JSX.Element;
        type: 'success' | 'danger' | 'info';
    } => {
        const statusMessages: Record<number, { message: string | JSX.Element; type: 'success' | 'danger' | 'info' }> = {
            201: { message: 'Fuelcard added successfully!', type: 'success' },
            400: {
                message: errorMessage ? errorMessage : 'Something went wrong, could not register fuelcard, please try again later',
                type: 'danger'
            },
            401: { message: <SessionExpiredErrorMessage />, type: 'danger' },
            403: { message: <SessionExpiredErrorMessage />, type: 'danger' },
            404: { message: 'Something went wrong, could not accept eula', type: 'danger' }
            // Add more status codes as needed
        };

        return (status && statusMessages[status]) || { message: defaultErrorMessage, type: 'danger' };
    };

    const onSubmit = async (data: FuelcardFormData) => {
        if (likelyIncorrectFcIdRegex.test(data.serialNumber) || likelyIncorrectFcIdRegex.test(fuelcard.serialNumber)) {
            setShowManualFallbackModal('warning');
            return;
        }
        await doSubmit(data);
    };

    const onIgnoreWarning = async () => {
        setShowManualFallbackModal(null);
        await doSubmit(fuelcard);
    };

    const doSubmit = async (data: FuelcardFormData) => {
        setIsSubmitting(true);
        window.scrollTo(0, 0); // scroll to top if all fields filled in to see submit message
        try {
            if (!isAdminOrManager) {
                data.userId = userInfo.id;

                if (eula == null) {
                    setMessageWithType('Eula has not loaded in yet, cannot submit fuelcard', 'danger');
                    return;
                }
                const eulaResponse = await acceptEula(userInfo.id, eula?.id);
                if (eulaResponse.status !== 200) {
                    return;
                }
            }

            const newFuelcard: Fuelcard = {
                id: undefined,
                serialNumber: sanitizeFcSerial(data.serialNumber),
                displayName: data.displayName,
                firstName: data.firstName,
                lastName: data.lastName,
                contactEmail: data.contactEmail,
                primaryEmail: data.primaryEmail,
                mobile: data.mobile.replaceAll(' ', ''),
                isBlocked: data.isBlocked || false,
                lastModificationDate: undefined,
                lastUsed: undefined,
                userId: data.userId || 0,
                car: {
                    id: null,
                    licensePlate: data.licensePlate,
                    brandId: null,
                    brand: data.brand,
                    modelId: null,
                    model: data.model,
                    reassignable: false,
                    userId: data.userId || 0
                },
                userType: UserType.XPLOREGROUP
            };

            if (mngrUserRegistrationState === 'newExternal') {
                newFuelcard.primaryEmail = newFuelcard.contactEmail;
            }
            if (isAdminOrManager) {
                switch (mngrUserRegistrationState) {
                    case 'newCronos':
                        newFuelcard.userType = UserType.CRONOS_UNCONFIRMED;
                        break;
                    case 'newExternal':
                        newFuelcard.userType = UserType.EXTERNAL;
                        break;
                    case 'newXploregroup':
                        newFuelcard.userType = UserType.XPLOREGROUP;
                        break;
                }
            }

            const response = await postRegisterFuelcard(newFuelcard);
            const { message, type } = handleResponseStatus(response.status, 'Something went wrong. Please try again.');
            setMessageWithType(message, type);
            if (type === 'success') {
                if (!/^\+?\d{9,13}$/.test(userInfo.phoneNumber) && userInfo.phoneNumber !== data.mobile) {
                    userInfo.phoneNumber = data.mobile;
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                }
                setFuelCard({ ...fuelcard, serialNumber: '', displayName: '' });
                if (!isAdminOrManager) {
                    navigate('/fuelcard');
                }
            }
        } catch (error) {
            if (!axios.isAxiosError(error)) {
                // Handle non-Axios errors
                setMessageWithType('Unexpected error. Please try again.', 'danger');
                setIsSubmitting(false);
                return;
            }
            const response = error.response;
            if (response && response.status === 400 && response.data && response.data.fuelcardRegistrationExceptionReason) {
                const fcProblemDetail = response.data as FuelcardRegistrationProblemDetail;

                switch (fcProblemDetail.fuelcardRegistrationExceptionReason) {
                    case 'CAR_BRANDS_DO_NOT_MATCH':
                    case 'CAR_MODELS_DO_NOT_MATCH': {
                        setMessageWithType(
                            isAdminOrManager ? (
                                <>
                                    A car with this license plate already exists but the brand or model does not match, you can update it via the{' '}
                                    <Link to={{ pathname: '/fuelcard/search' }} state={{ searchText: fuelcard.licensePlate }}>
                                        the fuelcard search page (via actions, manage car)
                                    </Link>
                                    . After you've updated it, register this fuelcard again.
                                </>
                            ) : (
                                <>
                                    A car with this license plate already exists and is linked to you but the brand or model you've entered do not
                                    match. If you want to update the brand or model, please do so via the{' '}
                                    <Link to={{ pathname: '/car' }}>my car page</Link>, after which you can register this fuelcard.
                                </>
                            ),
                            'danger'
                        );
                        break;
                    }
                    case 'FUELCARD_ALREADY_EXISTS': {
                        setMessageWithType(
                            isAdminOrManager ? (
                                <>
                                    This fuelcard already exists, you can manage it on{' '}
                                    <Link to={{ pathname: '/fuelcard/search' }} state={{ searchText: fuelcard.serialNumber }}>
                                        the fuelcard search page
                                    </Link>
                                    .
                                </>
                            ) : (
                                <>This fuelcard serial number is already registered.</>
                            ),
                            'danger'
                        );
                        break;
                    }
                    case 'USER_NOT_FOUND':
                    case 'CAR_ASSIGNED_TO_OTHER_USER':
                    case 'FUELCARD_ID_INVALID': {
                        setMessageWithType(fcProblemDetail.detail ?? 'Invalid fuelcard id', 'danger');
                        break;
                    }
                    case 'UNKNOWN_EXCEPTION':
                    default: {
                        setMessageWithType(
                            'Unexpected error. Please try again later.  If this error persists, please use the feedback button located in the navigation bar to report this.',
                            'danger'
                        );
                    }
                }
            } else {
                const { message, type } = handleResponseStatus(response?.status, 'Unexpected error. Please try again.', response?.data?.detail);
                setMessageWithType(message, type);
                if (type === 'info') {
                    setFuelCard({ ...fuelcard, serialNumber: '' });
                }
            }
        }
        setIsSubmitting(false);
    };

    const handleChange = <K extends keyof FuelcardFormData>(
        name: K,
        value: FuelcardFormData[K],
        setValue: (name: K, value: FuelcardFormData[K], options?: Partial<SetValueConfig>) => void
    ) => {
        setFuelCard({ ...fuelcard, [name]: value });
        setValue(name, value);
    };

    const onSubmitClick = () => {
        setUpdates('');
    };

    const onPasteFuelcardIdField = (e: ClipboardEvent<HTMLTextAreaElement>) => {
        const clipboardData = e.clipboardData;
        if (clipboardData) {
            // Prevent the default paste behavior
            e.preventDefault();
            const sanataizedFc = sanitizeFcSerial(clipboardData.getData('Text'));
            setFuelCard({ ...fuelcard, serialNumber: sanataizedFc });
            methods.setValue('serialNumber', sanataizedFc);
        }
    };

    const sanitizeFcSerial = (serial: string) => serial.trim().replace(/[:\s]/g, '').toUpperCase();

    return (
        <>
            <AuthenticatedTemplate>
                <div style={{ minHeight: 'var(--bs-content-min-heigth)', display: 'flex', flexDirection: 'column' }}>
                    <NavigationBar />

                    <InformationModal show={showInformationModal} onHide={() => setShowInformationModal(false)} />
                    <ManualFallbackModal
                        show={showManualFallbackModal}
                        onHide={() => setShowManualFallbackModal(null)}
                        onIgnoreWarning={onIgnoreWarning}
                    />
                    <FcAlreadyExistsModal
                        showAlreadyExistsModal={showAlreadyExistsModal}
                        setShowAlreadyExistsModal={setShowAlreadyExistsModal}
                        fuelcard={fuelcard}
                        clearFcSerial={() => handleChange('serialNumber', '', methods.setValue)}
                    />
                    <OtherUserCarModal
                        showOtherUserCar={showOtherUserCarModal}
                        setShowOtherUserCar={setShowOtherUserCarModal}
                        licensePlate={fuelcard.licensePlate}
                        clearEnteredCar={() => handleChange('licensePlate', '', methods.setValue)}
                        submitFeedback={() =>
                            setFeedBack({
                                ...feedback,
                                defaultMessage:
                                    'Hi, the system is telling me that the car with license plate ' +
                                    fuelcard.licensePlate +
                                    ' belongs to another user, but it belongs to me.  Would you be able to manually correct this?  Thanks in advance, ' +
                                    userInfo.firstName,
                                show: true
                            })
                        }
                    />

                    <ToastMessage
                        message={toast.message}
                        showToastMessage={toast.message !== ''}
                        bg={toast.bg}
                        autoHide={toast.autoHide}
                        title={toast.title}
                        setShowToastMessage={(show) => {
                            if (!show) {
                                setToast({
                                    message: '',
                                    bg: undefined,
                                    autoHide: true,
                                    title: undefined
                                });
                            }
                        }}
                    />
                    <FeedbackOffCanvas
                        {...feedback}
                        setShow={(show) => {
                            setFeedBack({
                                ...feedback,
                                show: show
                            });
                        }}
                    />

                    <div className={'fuelcardForm-wrapper'}>
                        <h2> Register a Fuelcard</h2>
                        {message && (
                            <Alert variant={messageType === 'danger' ? 'danger' : messageType === 'info' ? 'info' : 'success'}>
                                {message}
                                <div hidden={messageType != 'danger'}>
                                    <hr />
                                    <p>If you believe this to be a bug, you can submit feedback:</p>
                                    <Button
                                        style={{ width: 'fit-content' }}
                                        variant={'outline-secondary'}
                                        onClick={() =>
                                            setFeedBack({
                                                ...feedback,
                                                show: true,
                                                selectedFeedbackType: FeedBackType.BUG,
                                                additionalFeedBackTypes: [],
                                                defaultMessage: ''
                                            })
                                        }
                                    >
                                        Submit feedback
                                    </Button>
                                </div>
                            </Alert>
                        )}
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <ScanFuelCardButton
                                    methods={methods}
                                    setFuelcardNumber={(serial) => handleChange('serialNumber', sanitizeFcSerial(serial), methods.setValue)}
                                    setMessage={setMessage}
                                    setMessageType={setMessageType}
                                    setMessageTypeNfc={setMessageTypeNfc}
                                    setUpdates={setUpdates}
                                    handleResponseStatus={handleResponseStatus}
                                    setShowInformationModal={setShowInformationModal}
                                    showFallbackButton={!isAdminOrManager && (messageTypeNfc === 'error' || likelySupportsWebNfc === false)}
                                    setShowManualFallbackModal={setShowManualFallbackModal}
                                    setIsFcIdReadOnly={setIsFcIdReadOnly}
                                />
                                {likelySupportsWebNfc === false && !isAdminOrManager ? (
                                    <Alert variant={'warning'} style={{ fontSize: 'smaller', padding: '0.25em 0.5em' }}>
                                        Your browser likely does not support WebNFC to scan your fuelcard. Please click the "manually enter ID" button
                                        above for more information.
                                    </Alert>
                                ) : (
                                    <></>
                                )}
                                <FuelcardFormField
                                    messageTypeNfc={messageTypeNfc}
                                    scanText={updates}
                                    label="Fuelcard Serial Number"
                                    autoComplete="off"
                                    type="password"
                                    name="serialNumber"
                                    value={fuelcard.serialNumber}
                                    readOnly={!isAdminOrManager && isFcIdReadOnly}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        handleChange('serialNumber', sanitizeFcSerial(e.target.value), methods.setValue)
                                    }
                                    onBlur={() =>
                                        checkFuelcard(fuelcard.serialNumber).catch((e) => {
                                            if (axios.isAxiosError(e)) {
                                                if (e.response?.status === 409) {
                                                    setShowAlreadyExistsModal(true);
                                                }
                                            }
                                        })
                                    }
                                    register={methods.register}
                                    registerOptions={{
                                        required: 'Fuelcard serial number is required.',
                                        pattern: {
                                            value: /^[a-zA-Z0-9]*$/,
                                            message: 'Fuelcard serial number should only contain alphanumeric characters.'
                                        },
                                        minLength: {
                                            value: isAdminOrManager ? 0 : 8,
                                            message: 'Fuelcard serial number should be at least 8 characters long.'
                                        },
                                        maxLength: 14
                                    }}
                                    autoFocus={isAdminOrManager && !location.state?.serialNumber}
                                    onPaste={onPasteFuelcardIdField}
                                    minLength={isAdminOrManager ? 0 : 8}
                                    maxLength={14}
                                />

                                {likelyIncorrectFcIdRegex.test(fuelcard.serialNumber) ? (
                                    <p className="fuelcardFormFcIdWarning">
                                        The Fuelcard serial number is not written on the fuelcard! Please scan your fuelcard using&nbsp;
                                        <span
                                            style={{ color: '#1e90ff', textDecoration: 'underline', cursor: 'pointer' }}
                                            onClick={() => setShowManualFallbackModal('info')}
                                        >
                                            NFC
                                        </span>
                                        .
                                    </p>
                                ) : (
                                    <></>
                                )}
                                <FuelcardFormField
                                    label={
                                        <>
                                            Fuelcard Name
                                            <InformationPopover infoHeader="Fuelcard Display Name">
                                                <p>
                                                    The fuelcard display name helps you easily distinguish between your fuelcards. It can be any name
                                                    you choose.
                                                </p>
                                            </InformationPopover>
                                        </>
                                    }
                                    type="text"
                                    name="displayName"
                                    autoComplete="on"
                                    value={fuelcard.displayName}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('displayName', e.target.value, methods.setValue)}
                                    register={methods.register}
                                    registerOptions={{
                                        required: 'Fuelcard display name is required.',
                                        pattern: {
                                            value: /^[a-zA-Z0-9\-_/ ]{1,255}$/,
                                            message:
                                                'Fuelcard display name should be between 1 and 255 characters and contain only the following characters: a-z, A-Z, 0-9, _, - , ","'
                                        },
                                        maxLength: 255
                                    }}
                                />

                                <UserSearch
                                    fuelcard={fuelcard}
                                    isAdminOrManager={isAdminOrManager}
                                    handleChange={handleChange}
                                    methods={methods}
                                    mngrUserRegistrationState={mngrUserRegistrationState}
                                    mngrUserRegistrationSearchQuery={mngrUserRegistrationSearchQuery}
                                    setMngrUserRegistrationSearchQuery={setMngrUserRegistrationSearchQuery}
                                    setFuelCard={setFuelCard}
                                    setToast={setToast}
                                    setMngrUserRegistrationState={setMngrUserRegistrationState}
                                    setHideCarRegistration={setHideCarRegistration}
                                    autoFocus={isAdminOrManager && location.state?.serialNumber}
                                    userInfo={userInfo}
                                />

                                {hideCarRegistration ? (
                                    <></>
                                ) : (
                                    <CarRegistration
                                        fuelcard={fuelcard}
                                        isAdminOrManager={isAdminOrManager}
                                        handleChange={handleChange}
                                        methods={methods}
                                        setFuelCard={setFuelCard}
                                        setToast={setToast}
                                        mngrUserRegistrationState={mngrUserRegistrationState}
                                        userCar={userCar}
                                        userLoginId={userInfo.id}
                                        usePrefilledCar={usePrefilledCar}
                                        setUsePrefilledCar={setUsePrefilledCar}
                                        setShowOtherUserCarModal={setShowOtherUserCarModal}
                                    />
                                )}

                                {isAdminOrManager ? (
                                    <></>
                                ) : (
                                    <>
                                        {eula == null ? (
                                            <LoadingSpinner style={{ margin: '1em 0' }} />
                                        ) : (
                                            <FuelcardFormField
                                                label=""
                                                type="checkbox"
                                                autoComplete="off"
                                                name="terms"
                                                value={fuelcard.terms}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    handleChange('terms', e.target.checked, methods.setValue)
                                                }
                                                register={methods.register}
                                                termsCheckbox // Indicate it's a terms and conditions checkbox
                                                registerOptions={{
                                                    required: 'You must agree to the Terms and Conditions.'
                                                }}
                                            >
                                                <>
                                                    I accept{' '}
                                                    <a href={eula?.url} target="_blank" rel="noreferrer">
                                                        these
                                                    </a>{' '}
                                                    Terms and Conditions
                                                </>
                                            </FuelcardFormField>
                                        )}
                                    </>
                                )}
                                <div className="button-wrapper">
                                    {(isAdminOrManager && mngrUserRegistrationState !== 'initial' && mngrUserRegistrationState !== 'newUnknown') ||
                                    eula ? (
                                        <Button
                                            onClick={onSubmitClick}
                                            className="registrationButton"
                                            variant="success"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting ? (
                                                <>
                                                    <Spinner animation="border" variant="primary" role="status" size="sm" /> Submitting ...
                                                </>
                                            ) : (
                                                <>Submit</>
                                            )}
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </form>
                        </FormProvider>
                    </div>
                </div>
                <Footer />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <LoginRedirect />
            </UnauthenticatedTemplate>
        </>
    );
}
